import 'bootstrap';
import 'slick-carousel';

(function ($) {
	// On Dom Ready
	$(function () {
		// Toggle desktop submenu
		$(
			'.home-hero .overlay .container .caption ul.main-menu > li.menu-item-has-children > a'
		).on('click', function (e) {
			e.preventDefault();
			$(this).parent().find('ul.submenu').slideToggle();
		});

		// Toggle mobile menu
		$('.menu-open').on('click', function () {
			$('.mobile-menu-wrapper').addClass('active');
			$(this).addClass('is-active');
			$('body').addClass('noscroll');
		});
		$('.menu-close').on('click', function () {
			$('.mobile-menu-wrapper').removeClass('active');
			$('.menu-open').removeClass('is-active');
			$('body').removeClass('noscroll');
		});

		// equality champions Carousel
		$('.equality-champions .the-carousel').slick({
			autoplay: true,
			autoplaySpeed: 3000,
			dots: false,
			arrows: false,
			infinite: true,
			speed: 700,
			slidesToShow: 3,
			slidesToScroll: 3,
			rows: 0,
			responsive: [
				{
					breakpoint: 993,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
					},
				},
				{
					breakpoint: 579,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		});

		// featured employers Carousel
		$('.featured-employers .the-carousel').slick({
			autoplay: false,
			autoplaySpeed: 3000,
			dots: false,
			arrows: false,
			infinite: true,
			speed: 700,
			slidesToShow: 5,
			slidesToScroll: 1,
			rows: 0,
			vertical: true,
			responsive: [
				{
					breakpoint: 993,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 579,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
					},
				},
			],
		});

		// see-jobs-by Carousel
		$('.see-jobs-by .the-carousel').slick({
			autoplay: true,
			autoplaySpeed: 3000,
			dots: false,
			arrows: false,
			infinite: true,
			speed: 700,
			slidesToShow: 4,
			slidesToScroll: 1,
			rows: 0,
			vertical: true,
			responsive: [
				{
					breakpoint: 993,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 579,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
					},
				},
			],
		});

		// equality champions Carousel
		$('.employer-of-week .the-carousel').slick({
			autoplay: true,
			autoplaySpeed: 3000,
			dots: false,
			arrows: false,
			infinite: true,
			speed: 700,
			slidesToShow: 1,
			slidesToScroll: 1,
			rows: 0,
			fade: true,
		});

		// toggle search result
		$(document).on(
			'click',
			'.content.search-results .the-result .collapse-button',
			function (e) {
				e.preventDefault();

				$(this).parent().parent().toggleClass('active');
				$(this).parent().parent().find('.job-content').slideToggle();
			}
		);

		// toggle collapsible block
		$(document).on('click', '.collapsible-block > h3', function (e) {
			e.preventDefault();

			$(this).parent().toggleClass('active');
			$(this).parent().find('.collapse-content').slideToggle();
		});

		$('.password-container .form-control').on('focus', function (e) {
			$('#pass_message').addClass('active');
		});
	});

	// After page loaded completely
	$(window).on('load', function () {
		$('.mobile-menu-wrapper').addClass('initialized');
	});

	$(window).on('resize', function () {
		$('.menu-close').trigger('click');
	});
})(jQuery);
